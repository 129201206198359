.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.image-container img {
  height: 500px;
  margin-bottom: 20px;
}

.content-container {
  text-align: center;
  width: 875px;
  line-height: 2;
  font-size: large;
}
