/* This is the style for username/password labels
 and the box around it. */
.loginFormContainer {
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: center;
  height: 50vh;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #98989f60;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "poppins", sans-serif;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  margin-bottom: 8px;
  font-weight: bold;
}

.input {
  margin-bottom: 16px;
  height: 48px;
  width: 300px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding-left: 8px;
}

.button {
  background-color: #007bff;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button:hover {
  background-color: #0056b3;
}
.topSpaceContainer {
  margin-top: 120px;
}

.linkButton {
  background: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
