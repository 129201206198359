/* Navbar.css */
/*Background color for the page Navbar.js*/
body {
  margin: 0;
  padding: 0;
  background: white;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.menu-text {
  font-size: 9px;
}

/* Container for the logo
ie. the background for the top navigation where it says
"Muskingum Archery"*/
.logo-container {
  background-color: rgb(166, 16, 16);
  padding: 10px;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 1;
}

/* Navbar logo styling. The words "Muskingum Archery"*/
.navbar-logo {
  color: rgb(238, 235, 235);
  text-decoration: none;
  font-size: 2rem;
  border-radius: 10;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

/* Menu icon styling */
.menu-icon {
  padding: 10px;
  font-size: 29px;
  color: rgb(238, 235, 235);
}
.side-navbar {
  position: fixed;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: rgb(210, 210, 210);
  padding: 20px;
  z-index: 1000;
}
.side-nav-menu {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.side-nav-menu .nav-item {
  margin-bottom: 10px;
}

.side-nav-menu .nav-links {
  text-decoration: none;
  color: #252525;
  font-size: 1.1rem;
}

.side-nav-menu .nav-links:hover {
  color: #666;
}
.side-nav-menu .nav-links2 {
  text-decoration: none;
  color: #252525;
  font-size: 1.1rem;
}
