.container {
  width: 100%;
  margin-top: 0px;
  padding-bottom: 20px;
}

.content {
  text-align: center;
}

.tournaments-heading {
  background-color: #00000054;
  color: rgb(166, 16, 16);
  padding: 20px 30px;
  margin: 0;
  font-size: x-large;
}

.class-heading {
  background-color: #000000;
  color: #c5c4c4;
  padding: 15px 20px;
  margin: 0;
  font-size: large;
  max-width: 800px;
  margin: 0 auto;
}
.class-heading2 {
  background-color: #c5c4c4;
  color: #000000;
  padding: 15px 20px;
  margin: 0;
  font-size: large;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.tournamentList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.classGroup {
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(23, 19, 19, 0.516);
}

.tournamentListItem2 {
  color: rgb(0, 0, 0);
  padding: 0;
  margin: 0;
  max-width: 800px;
  margin: 0 auto;
  border-top: 2px solid rgb(166, 16, 16);
}

.tournamentListItem2 li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.tournamentListItem2 li span {
  padding: 15px 20px;
  font-size: large;
  flex: 1;
  text-align: center;
}
.tournamentList {
  list-style-type: none;
  padding: 0;
  margin: 20px auto;
  width: fit-content;
  text-align: center;
}
.tournamentList li a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  padding: 20px;
}
.tournamentList li a:hover {
  color: #b7b8b9;
}
.tournamentListItem {
  padding: 10px;
}
