.logout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logout-label {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
}

.logout-button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
}

.logout-button:hover {
  background-color: grey;
}
