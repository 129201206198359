/* Style for the form */
form {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #98989f60;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for labels */
label {
  display: block;
  margin-top: 10px;
}

/* Style for text inputs and selects */
input[type="text"],
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style for the "Create Tournament" button */
button {
  background-color: #0f0f10;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #2e3033;
}

/* Style for the custom location input when "Other" is selected */
input[type="text"][placeholder="Enter custom location"] {
  margin-top: 5px;
}

.topSpaceContainer {
  margin-top: 120px;
}
